<script>
  // <!--  后端传来的参数名称-->
  const COLLEGEABBR = 'collegeAbbr'; //学院简称
  const PARENTNAME = 'parentName'; //上级组织名字
  const ORGNAME = 'orgName'; //社团名字 //部门名字
  const FULLNAME = 'fullName'; //加入血赚的组织
  // const DEPARTMENTNAME = 'orgName';//部门名字
  const SUCCESSCODE = 0;//成功码
  const ORGID = "orgId";

  export default {
    COLLEGEABBR,
    PARENTNAME,
    ORGNAME,
    FULLNAME,
    // DEPARTMENTNAME,
    SUCCESSCODE,
    ORGID
  }
</script>
